import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import SEO from '../components/seo'

export default function Template({data}) {
    console.log(data);
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
    return (
        <>
        <Layout page='article'>
            <SEO title={frontmatter.title} description={frontmatter.description} image={featuredImgFluid} keywords={[``]} />
            <Banner title={`News`} subTitle={`Read the latest about how the Safer Childbirth Cities initiative is powering progress for maternal health and birth equity.`} titleColor='#FFFFFF' subTitleColor='#FFFFFF' hasArrow={true} reverse={true} />
            <section className='center-flex justify-center'>
                <Container className='post-container'>
                    <Row>
                        <Col className='post' lg='12'>
                            <p className='date'>{frontmatter.date}</p>
                            <h1>{frontmatter.title}</h1>
                            <hr className='horizontalRule dark' />
                            <div className='post-content' dangerouslySetInnerHTML={{ __html: html }} />
                        </Col>
                    </Row>
                </Container>
                <div className='arrow-down reverse centered'></div>
            </section>
            <section className='darkBlueColorBkgd'>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <div className='spacer60'></div>
                            <h2 className='whiteColor'>About Safer Childbirth Cities</h2>
                            <p className='whiteColor'>The Safer Childbirth Cities initiative aims to support community-based organizations in U.S. cities with a high burden of maternal mortality and morbidity to implement evidence-based interventions and innovative approaches to reverse the country’s maternal health trends and directly tackle racial inequities in maternal health outcomes. Our vision is for cities to become safer &mdash; and more equitable &mdash; places to give birth.</p>
                            <div className='spacer60'></div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
        </>
    )
}

export const pageQuery = graphql`
query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
}
`